export default [
    {prop: "404", name: "404"},
    {prop: "brand", name: "brand"},
    {prop: "bug", name: "bug"},
    {prop: "businessChance", name: "businessChance"},
    {prop: "case", name: "case"},
    {prop: "chart", name: "chart"},
    {prop: "client", name: "client"},
    {prop: "clipboard", name: "clipboard"},
    {prop: "close", name: "close"},
    {prop: "column-nav", name: "column-nav"},
    {prop: "component", name: "component"},
    {prop: "dashboard", name: "dashboard"},
    {prop: "designer", name: "designer"},
    {prop: "developer", name: "developer"},
    {prop: "documentation", name: "documentation"},
    {prop: "drag", name: "drag"},
    {prop: "edit", name: "edit"},
    {prop: "education", name: "education"},
    {prop: "email", name: "email"},
    {prop: "example", name: "example"},
    {prop: "excel", name: "excel"},
    {prop: "exit-fullscreen", name: "exit-fullscreen"},
    {prop: "eye-open", name: "eye-open"},
    {prop: "eye", name: "eye"},
    {prop: "filter", name: "filter"},
    {prop: "form", name: "form"},
    {prop: "fullscreen", name: "fullscreen"},
    {prop: "guide", name: "guide"},
    {prop: "home", name: "home"},
    {prop: "icon", name: "icon"},
    {prop: "international", name: "international"},
    {prop: "language", name: "language"},
    {prop: "link", name: "link"},
    {prop: "list", name: "list"},
    {prop: "lock", name: "lock"},
    {prop: "merchants", name: "merchants"},
    {prop: "message", name: "message"},
    {prop: "miniProgram", name: "miniProgram"},
    {prop: "money", name: "money"},
    {prop: "mWeb", name: "mWeb"},
    {prop: "nested", name: "nested"},
    {prop: "news", name: "news"},
    {prop: "pages", name: "pages"},
    {prop: "partner", name: "partner"},
    {prop: "password", name: "password"},
    {prop: "pdf", name: "pdf"},
    {prop: "people", name: "people"},
    {prop: "peoples", name: "peoples"},
    {prop: "pig", name: "pig"},
    {prop: "poster", name: "poster"},
    {prop: "qq", name: "qq"},
    {prop: "Refresh", name: "Refresh"},
    {prop: "search", name: "search"},
    {prop: "shopping", name: "shopping"},
    {prop: "singlePage", name: "singlePage"},
    {prop: "size", name: "size"},
    {prop: "skill", name: "skill"},
    {prop: "tab", name: "tab"},
    {prop: "table", name: "table"},
    {prop: "theme", name: "theme"},
    {prop: "tree-table", name: "tree-table"},
    {prop: "tree", name: "tree"},
    {prop: "user", name: "user"},
    {prop: "wechat", name: "wechat"},
    {prop: "zip", name: "zip"}
]