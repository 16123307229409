<template>
  <div class="base-page">
    <PageHeader class="base-page-header base-shadow"> </PageHeader>
    <SvgIconGroup class="base-page-section" />
  </div>
</template>

<script>
import SvgIconGroup from "@/components/SvgIconGroup";
import PageHeader from "@/components/PageHeader";
export default {
  components: {
    SvgIconGroup,
    PageHeader,
  },
};
</script>

<style lang="scss" scoped>
</style>