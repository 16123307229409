<template>
  <div class="svg-icon-list">
    <el-row>
      <el-col :span="2" v-for="item in svgIconList" :key="item.prop">
        <div class="svg-icon-item" :title="item.name">
          <span class="svg-icon"
            ><svg-icon :iconClass="item.prop"></svg-icon
          ></span>
          <span class="svg-name">{{ item.name }}</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import svgIconList from "@/icons/svgList.js";
export default {
  data() {
    return {
      listData: [],
    };
  },
  created() {
    this.svgIconList = svgIconList;
  },
};
</script>

<style lang="scss" scoped>
.svg-icon-list {
  box-sizing: border-box;
  width: 100%;
  .svg-icon-item {
    box-sizing: border-box;
    width: 100%;
    padding: 25px 10px;
    font-size: 13px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #f1eeee;
    }
    .svg-icon {
      display: inline-block;
      width: 100%;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 26px;
      margin-bottom: 6px;
    }
    .svg-name {
      display: inline-block;
      width: 100%;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>